import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Web アプリケーションの遅い処理を特定する話",
  "date": "2017-05-09T10:40:20.000Z",
  "slug": "entry/2017/05/09/194020",
  "tags": ["medley"],
  "hero": "./2017_05_09.png",
  "heroAlt": "web app"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部で「`}<a parentName="p" {...{
        "href": "https://job-medley.com"
      }}>{`ジョブメドレー`}</a>{`」の開発を担当している稲本です。`}</p>
    <p>{`先日、社内で行っている TechLunch という勉強会で「Web アプリケーションの遅い処理を特定する話」という話をしました。`}</p>
    <p>{`タイトルの意味する範囲が広めなので概要を記載すると以下の通りです。`}</p>
    <ul>
      <li parentName="ul">{`NewRelic から処理速度を見ていく`}</li>
      <li parentName="ul">{`ChromeDeveloperTools 処理速度を見ていく`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/RoR"
        }}>{`RoR`}</a>{`関連のプロファイラから処理速度を見ていく`}</li>
    </ul>
    <p>{`上記の様に Client から Server, Application までプロファイリングを行い遅い処理を特定していく流れの話をしました。`}</p>
    <h1>{`なぜこの話をしようと思ったのか`}</h1>
    <p>{`弊社ではフロントエンドエンジニア、サーバサイドエンジニアなどのポジションが明確には分かれておらず、どのバックグラウンドを持った人間も両方開発に携わる方針のため、エンジニア同士が、お互いの得意分野を補い合いながら、各々業務や学習を通して理解を深めるようにしています。`}</p>
    <p>{`ただ「`}<a parentName="p" {...{
        "href": "https://job-medley.com"
      }}>{`ジョブメドレー`}</a>{`」チームでは、現状パフォーマンス対策に関しては得意な人が対応する傾向にあります。`}</p>
    <p>{`そのため得意では無い人へも、どのように調べていけば良いかを共有し、少しでもエンジニア間の知識のギャップを小さくできればと思いフロントエンド〜サーバサイドまでのプロファイリングというテーマで発表しました。`}</p>
    <h1>{`話した内容`}</h1>
    <p>{`先述の取り話した内容は以下の通りです`}</p>
    <ul>
      <li parentName="ul">{`NewRelic から処理速度を見ていく`}</li>
      <li parentName="ul">{`ChromeDeveloperTools 処理速度を見ていく`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/RoR"
        }}>{`RoR`}</a>{`関連のプロファイラから処理速度を見ていく`}</li>
    </ul>
    <p><strong parentName="p">{`NewRelic の項`}</strong>{`、`}<strong parentName="p">{`ChromeDeveloperTools の項`}</strong>{`では、ユーザーが Browser を介してサービスを表示するまでの間に、どのような処理にどれぐらいの時間がかかっているか、それを見る方法について説明しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170508/20170508185724.png",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170508/20170508185842.png",
        "alt": null
      }}></img></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://d.hatena.ne.jp/keyword/RoR"
        }}>{`RoR`}</a>{`関連のプロファイラの項`}</strong>{`では、サーバーアプリケーションで実行する処理の速度の調査方法について説明しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170508/20170508190003.png",
        "alt": null
      }}></img></p>
    <p>{`※発表資料は`}<a parentName="p" {...{
        "href": "https://speakerdeck.com/medley/webapurikesiyonfalse-chi-ichu-li-wote-ding-suruhua"
      }}>{`こちら`}</a></p>
    <iframe id="talk_frame_390512" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/052ded9953be4bbdb157ab9ddcdfb2e3" width="710" height="596" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/webapurikesiyonfalse-chi-ichu-li-wote-ding-suruhua">speakerdeck.com</a></cite>
    <h1>{`まとめ`}</h1>
    <p>{`今回、TechLunch で各種プロファイリング方法について紹介しました。`}</p>
    <ul>
      <li parentName="ul">{`ブラウザを経由したパフォーマンスは NewRelic, ChromeDeveloperTools などで見ることが出来る`}</li>
      <li parentName="ul">{`アプリケーションのパフォーマンスは rack-mini-profiler, peek/rblineprof, stackprof`}</li>
      <li parentName="ul">{`正しく問題を把握することで誤った解決方法を選択してしまうリスクを回避できる`}</li>
    </ul>
    <p>{`ということについて話をしていきました。
個々のツールの使い方については、調べれば良質な文書が沢山ある中で、実運用上どのように調べれば良いのかにフォーカスし、その一例を紹介出来たのではないかと思います。`}</p>
    <p>{`今回は触れていませんが、これに限らずサーバーリソースから見る`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%DC%A5%C8%A5%EB%A5%CD%A5%C3%A5%AF"
      }}>{`ボトルネック`}</a>{`の調査方法や、負荷試験方法などについてもどこかで触れて行ければ良いなと考えています。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`メドレーでは医療業界に存在する課題に IT を駆使して取り組んでいきたいデザイナー・エンジニアを募集中です。`}</p>
    <p>{`皆さまからのご応募お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      